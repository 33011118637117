export default [
  {
    icon: 'mdi-monitor-dashboard',
    title: 'Início',
    to: '/admin/dashboard',
    group: '',
  },
  {
    icon: 'mdi-notebook',
    title: 'Lançamentos',
    group: '/admin',
    children: [
      {
        icon: 'mdi-notebook-edit',
        title: 'Lançamento do Faturamento',
        to: 'lancamentoFaturamento',
      },
      {
        icon: 'mdi-notebook-plus',
        title: 'Lançamento Avulso',
        to: 'lancamentoAvulso',
      },
    ],
  },
  {
    icon: 'mdi-calculator',
    title: 'Cálculo',
    group: '/admin',
    children: [
      {
        icon: 'mdi-calculator-variant-outline',
        title: 'Cálculo Mensal',
        to: 'calculos',
      },
      {
        icon: 'mdi-file-outline',
        title: 'Extrato Mensal Fornecedor',
        to: 'extratoFornecedor',
      },
      {
        icon: 'mdi-alpha-a-box-outline',
        title: 'Extrato Mensal Associado',
        to: 'extratoAssociado',
      },
      {
        icon: 'mdi-table-cog',
        title: 'Eventos de Cálculo',
        to: 'eventos',
      },
    ],
  },
  {
    icon: 'mdi-account-details',
    title: 'Pessoas',
    group: '/admin',
    children: [
      {
        icon: 'mdi-account-group',
        title: 'Associados',
        to: 'associados',
      },
      {
        icon: 'mdi-account-supervisor',
        title: 'Fornecedores',
        to: 'fornecedores',
      },
      {
        icon: 'mdi-account-tie',
        title: 'Gestores',
        to: 'gestores',
      },
      {
        icon: 'mdi-account-star',
        title: 'Administradores',
        to: 'administradores',
      },
    ],
  },
  {
    icon: 'mdi-factory',
    title: 'Unidades de Negócio',
    group: '/admin',
    children: [
      {
        icon: 'mdi-city-variant-outline',
        title: 'Unidades de Negócio',
        to: 'unidadesnegocio',
      },
      {
        icon: 'mdi-map-marker-path',
        title: 'Regionais',
        to: 'regionais',
      },
    ],
  },
  {
    icon: 'mdi-account-lock',
    title: 'Controle de Acesso',
    to: '/admin/acessos',
  },
]
