import Vue from 'vue'
import admin from './admin'
import gestor from './gestor'
import fornecedor from './fornecedor'
import associado from './associado'

export default () => {
  switch (Vue.prototype.$user.get().role) {
    case 'admin':
      return admin
    case 'gestor':
      return gestor
    case 'fornecedor':
      return fornecedor
    case 'associado':
      return associado
    default:
      return []
  }
}
